import { types } from 'mobx-state-tree'
import * as views from './trainStoreViews'
import * as actions from './trainStoreActions'
import Training from '../../models/training/Training'


const TrainStore = types
  .model('trainStore', {
    
    trainings: types.optional(
      types.array(
        Training
      ), []
    ),

    selectedTraining: types.maybeNull(
      types.reference(Training)
    ),

    loading: false,
    uploadingFiles: false,

    productContext: types.maybeNull(types.string),
    steps: types.optional(types.number, 1000),

    assetIds: types.optional(
      types.array(types.number), []
    ),

    launchTrainingOnMount: false,

    aiApiType: types.maybeNull(types.string),
    // bfl admin settings

    bflMode: types.maybeNull(types.string),
    bflIterations: types.maybeNull(types.number),
    bflLearningRate: types.maybeNull(types.number),
    bflCaptioning: types.maybeNull(types.boolean),
    bflPriority: types.maybeNull(types.string),
    bflFinetuneType: types.maybeNull(types.string),
    bflLoraRank: types.maybeNull(types.number),

  })
  .views(views.trainStoreViews)
  .actions(actions.trainStoreActions)

export default TrainStore