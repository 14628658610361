import { getRoot } from "mobx-state-tree"
export const trainStoreViews = self => ({
    
    get assetsFactory() {
        return getRoot(self).assetsFactory
    },
    get presetsFactory() {
        return getRoot(self).presetsFactory
        },
    get accountStore() {
        return getRoot(self).accountStore
    },
    get jobsFactory() {
        return getRoot(self).jobsFactory
    },
    get productsFactory() {
        return getRoot(self).productsFactory
    },
    get guiStore() {
        return getRoot(self).guiStore
    },
    get settingsStore() {
        return getRoot(self).settingsStore
    },
    get queryStore() {
        return getRoot(self).queryStore
    },
    get adminSettings() {
        if (self.aiApiType === "BLACK_FOREST_LABS") return self.bflAdminSettings
        else return {}
    },
    get bflAdminSettings() {
        return {
            mode: self.bflMode,
            iterations: self.bflIterations,
            learning_rate: self.bflLearningRate,
            captioning: self.bflCaptioning,
            priority: self.bflPriority,
            finetune_type: self.bflFinetuneType,
            lora_rank: self.bflLoraRank,
        }
    }
})